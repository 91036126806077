// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dash',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    firebase: {
      login: `${ROOTS.AUTH}/fire/login`,
      verify: `${ROOTS.AUTH}/fire/verify`,
      register: `${ROOTS.AUTH}/fire/register`,
      forgotPassword: `${ROOTS.AUTH}/fire/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
  },
};
